import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import './AgeVerification.css';

export function AgeVerification() {
    const [showModal, setShowModal] = useState(false);
    const [age, setAge] = useState<number | false>(false);
    const [formActive, setFormActive] = useState(true);
    const [formState, setFormState] = useState({
        month: '',
        year: '',
        day : ''
    });

    useEffect(() => {
        checkLocalStorage();
    }, []);

    const checkLocalStorage = () => {
        const age = localStorage.getItem('age');
        const expires = localStorage.getItem('ageExpires');
        const currentDate = new Date();
        const ageExpires = new Date(expires || '')
        if (age && Number(age) >= 21 && ageExpires && ageExpires > currentDate) {
            setAge(Number(age));
        } else {
            setShowModal(true);
        }
    }

    const saveInLocalStorage = (age : number) => {
        const currentDate = new Date();
        // expires in 2 days
        const ageExpires = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24 * 2);
        localStorage.setItem('age', age.toString());
        localStorage.setItem('ageExpires', ageExpires.toString());
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!formState.month || !formState.year || !formState.day) {
            return;
        }
        if(Number(formState.month) < 1) {
            return;
        }
        // calculate age in years from date input year-month-day
        const { month, year, day } = formState;
        const birthDate = new Date(Number(year), Number(month) - 1, Number(day));
        const currentDate = new Date();
        const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();
        const ageInYears = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25));

        setAge(ageInYears);
        saveInLocalStorage(ageInYears);
    };

    useEffect(() => {
        if(age && age >= 21 && age < 120) {
            setShowModal(false);
        }
    }, [age])

    // validate month
    useEffect(() => {
        if(formState.month) {
            const month = Number(formState.month);
            if(month > 12) {
                setFormState({
                    ...formState,
                    month: '12'
                })
            }
        }
    },  [formState.month])

    // validate day
    useEffect(() => {
        if(formState.day) {
            const day = Number(formState.day);
            if(day === 0 || day > 31) {
                setFormState({
                    ...formState,
                    day: '31'
                })
            }
        }
    }, [formState.day])

    // validate year
    useEffect(() => {
        if(formState.year) {
            const year = Number(formState.year);
            if(year === 0 || year > new Date().getFullYear()) {
                setFormState({
                    ...formState,
                    year: new Date().getFullYear().toString()
                })
            }
        }
    }, [formState.year])

    return (
        <Modal className="age-verification-modal" open={showModal} onClose={() => age && setShowModal(false)}>
            <div className="age-verification-modal-content">
                <img src="/images/logo.png" alt="logo" />
                <h2>Welcome!</h2>
                <p>{
                        age && age < 21 ? `You must be 21 years old or older to access this site.` : 'Please enter your date of birth to continue.'
                    }</p>

                <form onSubmit={handleSubmit} className="age-verification-modal-form">
                    {/* <input disabled={!formActive} type="date" value={formState.date} onChange={(e) => setFormState({ ...formState, date: e.target.value })} /> */}
                    <div className="age-verification-modal-form-date">
                        <input placeholder="MM" disabled={!formActive} type="number" value={formState.month} onChange={(e) => setFormState({ ...formState, month: e.target.value })} />
                        <input placeholder="DD" disabled={!formActive} type="number" value={formState.day} onChange={(e) => setFormState({ ...formState, day: e.target.value })} />
                        <input placeholder="YYYY" disabled={!formActive} type="number" value={formState.year} onChange={(e) => setFormState({ ...formState, year: e.target.value })} />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </Modal>
    )
}