import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './components/Home/Home';
import { Footer } from './components/Footer';
import Loading from './components/Loading';
import AboutPage from './components/About/About';
import { AgeVerification } from './components/AgeVerification';
import { ContactModal } from './components/ContactModal';
import { Fab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [loading, setLoading] = useState(true);
  const [homeConfig, setHomeConfig] = useState({});
  const [aboutConfig, setAboutConfig] = useState({});
  const location = useLocation();

  const [ contactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    if(location.pathname === '/') {
      fetchConfig('home-config.json').then((data) => {
        setHomeConfig(data);
        setLoading(false);
      });
    }

    if(location.pathname === '/about') {
      fetchConfig('about-config.json').then((data) => {
        setAboutConfig(data);
        setLoading(false);
      });
    }
  }, [location.pathname]);

  const fetchConfig = async (url: string) => {
    return await (await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })).json();
  }
  const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    backgroundColor: '#146C43',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#146C43',
        color: '#fff'
      }
  };
  return (
    <div className="App">
      {
        loading ? <Loading/> : <>
          <Navbar />
          <AgeVerification/>
          <ContactModal isOpen={contactModalOpen} toggleOpen={() => setContactModalOpen(!contactModalOpen)}/>
          <Fab sx={fabStyle} className="contact-us-fab" onClick={() => setContactModalOpen(true)}>
            <FontAwesomeIcon icon={faPhone} />
          </Fab>
          <Routes>
            <Route path="/" element={<HomePage config={homeConfig}/>} />
            <Route path="/about" element={<AboutPage config={aboutConfig}/>} />
          </Routes>
          <Footer/>
        </>
      }

    </div>
  );
}

export default App;
