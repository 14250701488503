import React from 'react';
import HeroSection from './HeroSection/HeroSection';
import ContactBar from './ContactBar/ContactBar';
import { BestSellers } from './BestSellers/BestSellers';
import Reviews from './Reviews/Reviews';

function HomePage({config}: {
    config: any;
}) {

  return (
    <div className="HomePage">
        {
            config['hero-section'] && <HeroSection config={config['hero-section']} />
        }
        {
            config['contact-bar'] && <ContactBar config={config['contact-bar']} />
        }
        {
            config['best-sellers'] && <BestSellers config={config['best-sellers']} />
        }
        {
            config['reviews-section'] && <Reviews config={config['reviews-section']} />
        }
    </div>
  );
}

export default HomePage;
