import React from "react";
import parse from 'html-react-parser';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './HeroSection.css';

export default function HeroSection({config}: 
    {
        config: {
            title: string;
            backgroundImage: string;
            buttonText: string;
        }
    }
) {

  return (
    <section className="hero-section"
    >
        <a className="anchor" id="hero-section" style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            zIndex: -1,
        }}></a>
        <div
            className="hero-background"
        >
            {/* <img src={config.backgroundImage} alt="Background Image" /> */}
            <LazyLoadImage 
                src={config.backgroundImage}
                effect="blur"
                alt="Background Image"
                width={"100%"}
                height={"100%"}
            />
        </div>
        <div className="container">
            <h1 className="hero-title">{parse(config.title)}</h1>
            <a href="#best-sellers" className="link-button">{config.buttonText}</a>
        </div>
    </section>
  )
}