import ProductCard from "./Products/ProductCard";
import './BestSellers.css';
import { useEffect, useState } from "react";
import throttle from 'lodash.throttle';
import { Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

export interface Product {
    name: string;
    type: string;
    percentage: string;
    unit: string;
    price: string;
    priceUnit: string;
    image: string;
    hotBadge: boolean;
    hidden: boolean;
}

export function BestSellers ({ config }: {
    config: {
        title: string;
        backgroundImage: string;
        items: Product[];
    }
}) {
    const seperatedItems = config.items.reduce((acc, item, index) => {
        if(item.hidden) {
            acc.hide.push(item);
        } else {
            acc.show.push(item);
        }
        return acc;
    }, {
        show: [] as Product[],
        hide: [] as Product[],
    } as {
        show: Product[];
        hide: Product[];
    });
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(8);
    const [products, setProducts] = useState<Product[]>([]);
    const [hasMore, setHasMore] = useState(true);


    const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);
    
    const isScrollInView = (offset: number = 0) => {
        if (!scrollRef) return false;
        const top = scrollRef.getBoundingClientRect().top;
        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    const handleScroll = throttle(() => {
       const isInView = isScrollInView(-50);
       if(isInView && hasMore) {
            tryNextPage();
       } else if(!hasMore) {
            window.removeEventListener('scroll', handleScroll);
       }
    }, 500);

    const handleUnload = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        setProducts(seperatedItems.show.slice(startIndex, endIndex));
    }, [])

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     window.onbeforeunload = handleUnload;
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     }
    // });

    const tryNextPage = () => {
        const maybeStartIndex = startIndex + 9;
        let maybeEndIndex = endIndex + 9;
        if(maybeEndIndex >= seperatedItems.show.length) {
            setHasMore(false);
            maybeEndIndex = seperatedItems.show.length;
        }
        if(maybeStartIndex >= seperatedItems.show.length) {
            return;
        }
        setStartIndex(maybeStartIndex);
        setEndIndex(maybeEndIndex);
        const newProducts = [...products, ...seperatedItems.show.slice(maybeStartIndex, maybeEndIndex)]
        setProducts(newProducts);
        console.log({startIndex, endIndex, maybeStartIndex, maybeEndIndex, newProducts});

    }

    const handleViewLess = () => {
        setStartIndex(0);
        setEndIndex(8);
        setProducts(seperatedItems.show.slice(0, 8));
        setHasMore(true);
    }

    return (
        <section className="best-sellers" >
            <a className="anchor" id="best-sellers"></a>
            <div className="container">
                <h2 className="best-sellers-title">{config.title}</h2>
                    <div className="best-sellers-products">
                    {
                        <TransitionGroup className="best-sellers-products">
                            {
                                products.map((product, index) => (
                                    <Collapse timeout={500} key={index}><ProductCard key={index} product={product} /></Collapse>
                                ))
                            }
                        </TransitionGroup>
                    }
                    {
                        seperatedItems.hide.length > 0 && seperatedItems.hide.map((product, index) => (
                            <ProductCard key={index} product={product} />
                        ))
                    }
                    </div>
                    {
                        hasMore ? <button className="best-sellers-load-more" onClick={tryNextPage}>View More</button> : <button className="best-sellers-load-more" onClick={handleViewLess}>View Less</button>
                    }
            </div>
            <div className="best-sellers-overlay">
                <div  style={{
                    backgroundImage: `url(${config.backgroundImage})`,
                    // tile background image
                    backgroundSize: '200px 200px',
                    width: '100%',
                    height: '100%',

                }}></div>
            </div> 
        </section>
    )
}