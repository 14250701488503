import { Modal } from "@mui/material";
import './AgeVerification.css';

export function ContactModal({
    isOpen,
    toggleOpen,
}: {
    isOpen: boolean;
    toggleOpen: () => void;
}) {
    return (
        <Modal className="age-verification-modal" open={isOpen} onClose={() => toggleOpen()}>
            <div className="age-verification-modal-content">
                <img src="/images/logo.png" alt="logo" />
                <h2>Contact Us</h2>
                <p>{
                        'Call or text us at (505) 750-0018!'
                    }</p>
            </div>
        </Modal>
    )
}