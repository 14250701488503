import { faClock, faMapPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './ContactBar.css';

export default function ContactBar ({ config }: {
    config: {
        address: string;
        phone: string;
        openHours: string;
    }
}) {
    return (
        <div className="contact-bar">
            <div className="container">
                <div className="contact-bar-content">
                    <div className="contact-bar-item">
                        <FontAwesomeIcon icon={faMapPin} />
                        <span>{config.address}</span>
                    </div>
                    <div className="contact-bar-item">
                        <FontAwesomeIcon icon={faClock} />
                        <span>{config.openHours}</span>
                    </div>
                    <div className="contact-bar-item">
                        <FontAwesomeIcon icon={faPhone} />
                        <span>{config.phone}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}