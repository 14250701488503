import './Footer.css';

export function Footer () {
    return (
        <footer className="footer">
            <div className="logo">
                <img src="images/logo.png" alt="Logo" />
            </div>
            <div className="footer-links">
                <h2>Keep in touch</h2>
                <ul>
                    <li>(505) 750-0018</li>
                    <li>buddingbar@gmail.com</li>
                    <li><a target="_blank" href="https://www.facebook.com/people/Budding-Bar-Dispensary/100092347293333/">Facebook</a></li>
                    <li>804 S Main St. Belen, NM 87002</li>
                </ul>
            </div>
        </footer>
    )
}