import React from 'react';
import ExperienceMore from './ExperienceMore/ExperienceMore';
import HeroSection from './HeroSection/HeroSection';
import './About.css';
import AboutSection from './AboutSection/AboutSection';

function AboutPage({config}: {
    config: any;
}) {

  return (
    <div className="AboutPage">
        {
            config['hero-section'] && <HeroSection config={config['hero-section']} />
        }
        {
            config['about-section'] && <AboutSection config={config['about-section']} />
        }
        {
            config['experience-more'] && <ExperienceMore config={config['experience-more']} />
        }
    </div>
  );
}

export default AboutPage;
